.dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-dialog .MuiPaper-root {
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.dialog-input .MuiInputBase-root {
    background-color: #444;
}

.dialog-input .MuiInputBase-input {
    color: white;
}

.dialog-input .MuiInputBase-input::placeholder {
    color: white !important;
}

.dialog-input .MuiInputLabel-root {
    color: white !important;
}

.dialog-input .MuiInputBase-root.Mui-focused {
    border-color: white !important;
}

/* Target auto-filled input fields for both Webkit (Chrome, Safari) and Firefox */
.dialog-input .MuiInputBase-root .MuiInputBase-input:-webkit-autofill {
    background-color: #444 !important;
    -webkit-box-shadow: 0 0 0px 1000px #444 inset;
    -webkit-text-fill-color: white !important;
}

.dialog-input .MuiInputBase-root .MuiInputBase-input:-moz-autofill {
    background-color: #444 !important;
    color: white !important;
}


.dialog-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    color: red;
}

.dialog-terms {
    color: white;
    font-size: 0.8rem;
    margin-top: 10px;
}

.dialog-terms-link {
    color: orange;
    cursor: pointer;
}

.dialog-submit-btn {
    background-color: #fca311;
    color: black;
    font-weight: bold;
    margin-top: 20px;
}

.dialog-submit-btn:hover {
    background-color: #ffbb33;
}