@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.feature-main-container {
    padding: 51px;
    margin-top: -100px !important;
}

:root {
    --color-text: #616161;
    --color-text-btn: #ffffff;    
    --card-gradient-color: #0c4fe00f;
}

.card-wrap {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #4E7FE8;
    overflow: hidden;
    color: var(--color-text);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;
    transform: translateY(30px);
    opacity: 0;
    animation: fadeInUp 0.5s forwards;
    height: 250px;
    box-sizing: border-box;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.card-wrap.is-visible {
    transform: translateY(0);
    opacity: 1;
}


.card-wrap:hover {
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 15px 30px,
        rgba(0, 0, 0, 0.22) 0px 8px 10px;
}


.card-wrap.dark {
    border: 5px solid #333;
    background: #333;

}

.card-wrap.dark .card-content {
    color: #fff;
}


@media (max-width: 768px) {
    .card-wrap {
        width: 100%;
    }
}

.card-header {
    height: 145px;
    padding: 4px 8px;
    width: 100%;
    display: grid;
    place-items: center;
    background: linear-gradient(to bottom left, var(--card-gradient-color), var(--card-gradient-color));
}

.card-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
    background-color: #fff;
}

.card-wrap.dark .card-icon {
    background-color: #121212;
}

.card-icon img {
    width: 54%;
}

.card-header span {
    color: #fff;
    font-size: 72px;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    color: #000;
    padding: 12px;

}

.card-title {
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.card-text {
    text-align: center;
    font-size: 12px;
    margin-bottom: 20px;
}

.card-btn {
    border: none;
    border-radius: 100px;
    padding: 5px 30px;
    color: #fff;
    margin-bottom: 15px;
    text-transform: uppercase;
}

