.notification-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    background: #00031F;
    color: azure;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2000;
    height: 27px;
}

.notification-banner.dark-mode {
    background-color: #333333;

}

.notification-message {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.timer {
    font-size: 0.9rem;
    margin: 5px 0;
    font-weight: 700;
    color: coral;
    margin-left: 10px;
    margin-right: 10px;
}

@keyframes blinkAndFlash {
    0% {
        opacity: 1;
        color: azure;
    }

    50% {
        opacity: 1;
        color: whitesmoke;
    }

    100% {
        opacity: 1;
        color: red;
    }
}

.apply-now {
    font-weight: bold;
    animation: blinkAndFlash 1s infinite;
}

@media (max-width: 768px) {
    .notification-banner {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50px;
        font-size: 11px;
        height: 60px;
        z-index: 999;

    }

    .timer,
    .close-btn {
        display: none;
    }

    .notification-message {
        font-size: 11px;
    }

}

@media (max-width: 1024px) {
    .notification-banner {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 0px;
        font-size: 11px;
        z-index: 999;
    }
}