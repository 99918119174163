

.offer-popup-image {
    width: 40%;
    border-radius: 10px;
}.offer-popup-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer-popup-image-overlay {
    position: relative;
    display: inline-block;
}

.offer-popup-image {
    width: 100%;
    display: block;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    text-align: center;
}
