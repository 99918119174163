/* Default styles */
.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 50px;
    height: 72px;
    background: #fff;
    transition: box-shadow 0.3s ease;
    position: fixed;
    top: 27px;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 2px -2px rgba(0, 3, 1, .2);
}


.navbar.scrolled {
    box-shadow: 0 2px 2px -2px rgba(0, 3, 1, .2);
}

.dropdown {
    position: relative;
}

.dropdown-toggle {
    cursor: pointer;
    margin: 0 15px;
    display: flex;
}


.dark-mode.navbar {
    background-color: #121212;
    box-shadow: 0 4px 4px -2px rgba(247, 248, 247, 0.2);

}

.navbar-content {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 29px;
}

.logo img {
    width: 126px;
    padding-top: 16px;
    padding-bottom: 0px;
}

.nav-links {
    display: flex;
    gap: 20px;
    margin-left: 20px;
}

.nav-links a {
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    position: relative;
}

.nav-links a:hover {
    color: #333;
}

.nav-links a.active {
    color: #0C4FE0;
    font-weight: bold;
}

.nav-links a::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.nav-links a:hover::after,
.nav-links a.active::after {
    background-color: #0C4FE0;
}

.right-actions {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto;
}

.action-link {
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.action-link:hover {
    color: #333;
}

.dark-mode .action-link {
    color: #fff;
}

.get-started-btn {
    background-color: #000;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.get-started-btn:hover {
    background-color: #333;
}

.dark-mode .nav-links a {
    color: #fff;
}

.dark-mode .nav-links a.active {
    color: #fff;
}

.dark-mode .get-started-btn {
    background-color: #fff;
    color: #000;
}

.dark-mode .get-started-btn:hover {
    background-color: #ddd;
}


.light-mode .nav-links a,
.light-mode .action-link {
    color: #000;
}

.light-mode .get-started-btn {
    background-color: #000;
    color: #fff;
}

.light-mode .get-started-btn:hover {
    background-color: #333;
}

.toggle-theme-btn {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    color: inherit;
}


/* Mobile devices */
@media (max-width: 768px) {
    .navbar {
        padding: 8px 15px;
        top: 0px;
        box-shadow: none;
    }

    .navbar.scrolled {
        box-shadow: 0 2px 2px -2px rgba(0, 3, 1, .2);
    }

    .navbar-content {
        justify-content: space-between;
    }

    .logo img {
        width: 120px;
    }

    .nav-links {
        display: none;
    }

    .menu-toggle {
        margin-right: auto;
    }

    .right-actions {
        display: flex;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .logo img {
        width: 140px;
    }

    .nav-links {
        gap: 15px;
        font-size: 0.9rem;
    }

    .right-actions {
        gap: 10px;
    }

    .get-started-btn {
        padding: 6px 12px;
    }
}

@media (min-width: 1400px) {
    .nav-links {
        display: flex;
    }

    .navbar {
        padding: 0px 150px;
    }

    .menu-toggle {
        display: none;
    }

    .right-actions {
        display: flex;
    }
}

.mobile-offer {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: #ff4081;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    transition: bottom 0.5s ease;
    z-index: 1000;
}

.mobile-offer.show {
    bottom: 0;
}

.offer-content h1 {
    font-size: 24px;
    margin: 0 0 10px;
    animation: fadeIn 0.5s ease forwards;
}

.offer-content p {
    font-size: 18px;
    margin: 0 0 20px;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: 0.2s;
}

.offer-button {
    background-color: white;
    color: #ff4081;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.offer-button:hover {
    background-color: #f1f1f1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.mobile-offer.dark {
    background-color: #424242;
}

.menu-icon {
    font-size: 18px;
    color: #333;
    margin-left: 10px;
}

@keyframes badgePulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.04);
    }

    100% {
        transform: scale(1);
    }
}

@media (max-width: 600px) {
    .offer-badge {
        flex-direction: column;
        text-align: center;
        padding: 10px;
    }

    .logo {
        margin-bottom: 6px;
    }

    .menu-icon {
        margin-top: 8px;
    }
}