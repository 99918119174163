@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.landing-page {
    color: #000;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    position: relative;
}

.landing-page.light {
    background-color: #fff;
}

.landing-page.dark {
    background-color: #121212;
    color: #fff;
}

.landing-page.dark .text-transition {
    color: #ee624a;
}


.main-title {
    font-size: 2rem;
    width: 100%;
    margin: 0;
    text-align: center;
    text-align: center;
    font-weight: bold;
    color: #00031F;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.landing-page.dark .main-title {
    color: #fff;
}

.text-transition {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    font-size: 2rem;
    color: #0C4FE0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}


.offer-text {
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    font-family: monospace;
    animation: badgePulse 1s infinite;
    color: orangered;
}

.offer-text a {
    display: inline-block;
    color: #0056b3;
    text-decoration: none;
    font-size: 13px;
    margin-top: 3px;
    transition: color 0.3s ease;
}

.offer-text a:hover {
    color: #0056b3;
}

.landing-page.dark .text-subsection {
    color: #fff;
}

.text-subsection {
    text-align: center;
    color: #00031F;
}

.hosted-text {
    margin: 20px auto;
    font-size: 1rem;
    text-align: center;
    color: inherit;
    color: #00031F;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.landing-page.dark .hosted-text {
    color: #fff;
}


.description {
    margin: 20px 0;
    font-size: 1.2rem;
    text-align: center;
}

.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
}

.button-group>* {
    margin-right: 20px;
}

.button-group>*:last-child {
    margin-right: 0;
}




@media (max-width: 768px) {
    .main-title {
        font-size: 1.4rem !important;
    }

    .text-transition {
        font-size: 1.3rem;
    }

    .hosted-text {
        font-size: 14px;
    }

    .button-group {
        flex-direction: column;
        align-items: stretch;
    }

    .button-group>* {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .button-group>*:last-child {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .button {
        font-size: 0.9rem;
    }
}


.ribbon-sale {
    background: #FEC108;
    color: #00031f;
    font-weight: bold;
    padding: 1px 10px;
    transform: rotate(360deg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    white-space: nowrap;
}

.ribbon-sale span {
    display: inline-block;
    font-size: 16px;
    position: relative;
}

.ribbon-sale::before,
.ribbon-sale::after {
    content: "";
    position: absolute;
    bottom: -10px;
    border: 10px solid transparent;
}

.ribbon-sale::before {
    left: 0;
    border-right-color: #FEC108;
}

.ribbon-sale::after {
    right: 0;
    border-left-color: #FEC108;
}
