@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.pricing-container.dark .table {
  background: #333333;

}

.table {
  position: relative;
  border-radius: 10px;
  height: 97%;
  padding: 16px;
  text-align: center;
  background: linear-gradient(0deg, rgba(#27A5EA) 0%, rgba(#00C853) 100%);
}

.table.premium {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.table .price-section {
  display: flex;
  justify-content: center;
}

.table .price-area {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 2px;
}

.aj_p {
  text-align: center;
}

p.aj_des {
  color: grey;
  padding-bottom: 30px;
  font-size: 14px;
}

.table.premium {
  margin: -20px;
}

.premium_all {
  padding-top: 22px;
}

.price-area .inner-area {
  height: 100%;
  width: 100%;
  text-align: center;
  color: #fff;
}

.price-area .inner-area .text {
  font-size: 30px;
  font-weight: 400;
  margin-left: 20px;
}

.price-area .inner-area .price {
  font-size: 45px;
  font-weight: 500;
  margin-left: 8px;
}

.table .package-name {
  width: 100%;
  height: 2px;
  margin: 35px 0;
  position: relative;
}

.table .features li {
  margin-bottom: 15px;
  list-style: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.features li .list-name {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;

}

.features li .icon {
  font-size: 15px;
  text-align: end;
}

.features li .icon.check {
  color: #2db94d;
}

.features li .icon.cross {
  color: #cd3241;
}

.table .btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.table .btn button {
  width: 67%;
  height: 42px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}


.basic ::selection,
.basic .price-area,
.basic .inner-area,
.basic .head_tab h2 {
  color: #01B247;
}

.basic .btn button {
  background: #01B247;
  color: #fff;
  margin-top: -63px;
}


.premium ::selection,
.premium .price-area,
.premium .inner-area,
.premium .head_tab h2 {
  color: #01B247;
}

.premium .btn button {
  background: #01B247;
  color: #fff;
  margin-top: -63px;
}


.ultimate ::selection,
.ultimate .price-area,
.ultimate .inner-area,
.ultimate .head_tab h2 {
  color: #01B247;
}

.ultimate .btn button {
  background: #01B247;
  color: #fff;
  margin-top: -63px;
}

@media (max-width:756px) {
  .wrapper .table {
    width: 100%;
  }

  .table.premium {
    margin: 40px 0px;
  }

  .table.ultimate {
    margin: 0px;
  }
}



.table .ribbon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.table .ribbon::before,
table .ribbon::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid coral;
  border-top-color: transparent;
  border-left-color: transparent;
}

.table .ribbon::before {
  top: 0px;
  right: 15px;
}

.table .ribbon::after {
  bottom: 15px;
  left: 0px;
}

.table .ribbon span {
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: coral;
  padding: 10px 0;
  color: whitesmoke;
  font-weight: bold;
  text-align: center;
  font-size: 17px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}

.pricing-container {
  margin: 20px;
}

@media (max-width: 768px) {
  .table {
    height: auto;
  }
}