/* Sidebar.css */

.sidebar {
    width: 95%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3000;
}

.sidebar.dark-mode {
    background-color: #000000;
    color: #fff;
}

.sidebar.light-mode {
    background-color: #fff;
    color: #000;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: inherit;
    height: 20px;
    width: 20px;
    color: red;
    border-radius: 50%;
    cursor: pointer;
}

.sidebar .theme-toggle {
    margin-top: auto;
}

.sidebar .MuiListItem-root {
    padding: 10px 20px;
    color: inherit;
}

.sidebar .MuiListItem-root:hover {
    background-color: #F7F7F7;
    color: #000;
    border-radius: 5px;
}

.sidebar .MuiListItem-root.active {
    background-color: #4a148c;
    color: #fff;
}

@media (max-width: 600px) {
    .sidebar {
        z-index: 3000;
    }
}

.bottom-section {
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-bottom: 4px;
}

.social-icons {
    display: flex;
    margin-top: 1rem;
    margin-left: 11px;
}