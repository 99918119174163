.feature-heading {
    text-align: center;
    margin: 50px 0;
}

.heading {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    position: relative;
    text-transform: capitalize;
    color: #0C4FE0;
    transition: color 0.3s ease;
}

.subheading {
    font-size: 16px;
    color: #000000;
    margin: 1px 0 0;
    transition: color 0.3s ease;
    text-transform: capitalize;
    font-weight: 400;
}

.underline {
    content: '';
    display: block;
    width: 70px;
    height: 4px;
    background-color: #0C4FE0;
    border-radius: 10px;
    margin: 10px auto;
}

.feature-heading.dark .heading {
    color: #fff;
}

.feature-heading.dark .subheading {
    color: #bbb;
}

.feature-heading.dark .underline {
    background-color: #F7F7F7;
}

@media (max-width: 480px) {
    .heading {
        font-size: 16px;
    }

    .subheading {
        font-size: 14px;
    }
}