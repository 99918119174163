body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    background-color: transparent;
    /* Background color of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background: lightgray;
    /* Gradient for the scrollbar thumb */
    border-radius: 50px;
    /* Rounded edges for the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: lightgray;
    /* Gradient on hover */
}

/* For Firefox */
* {
    scrollbar-width: thin;
    /* Set the scrollbar width to thin */
    scrollbar-color: lightgray;
    /* Thumb color and track color */
}

*::-scrollbar {
    border-radius: 10px;
    /* Rounded edges for the scrollbar */
}

*::-scrollbar-thumb {
    background: lightgray;
    /* Gradient for the scrollbar thumb */
}

*::-scrollbar-thumb:hover {
    background: lightgray;
    /* Gradient on hover */
}

input,
select,
textarea {
    font-size: 16px;
    /* Set the font size to prevent zooming */
}