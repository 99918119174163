.primary-button {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 10px 22px; /* Default padding */
    border-radius: 25px;
    text-decoration: none;
    font-size: 16px; /* Default font size */
    position: relative;
    overflow: hidden;
    height: 42px;
    transition: background-color 0.3s ease;
}

.primary-button:hover {
    background-color: #333;
}

.primary-button.light-mode,
.primary-button.dark-mode {
    background-color: #0C4FE0;
    color: #fff;
    font-weight: bold;
    display: flex;
}

.primary-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1));
    animation: shine 1.5s infinite;
}

@keyframes shine {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}

.button-arrow {
    margin-left: 10px;
    display: inline-block;
    transition: transform 0.3s ease;
}

.primary-button:hover .button-arrow {
    transform: translateX(5px);
}

