.slider-container {
    width: 100%;
    max-width: 900px;
    margin: auto;
    position: relative;
    margin-bottom: 20px;
}

.slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.slider-image {
    width: 100%;
    height: auto;
    object-fit: fit;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid gray;
}

.slider-image-pos {
    width: auto;
    height: 100vh;
    object-fit: fit;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid gray;
    margin: 0px auto;
}

.dots-container {
    background-color: #ddd;
    border-radius: 2px;
    padding: 10px;
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-dots {
    bottom: 15px;
}

.slick-dots li {
    margin: 0 10px;
}

.dot-button {
    background: rgb(61, 56, 56);
    border-radius: 50%;
    border: none;
    color: #fff;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s;
}

.slick-dots li.slick-active .dot-button {
    background: rgb(15, 15, 15);
    opacity: 1;
}

.dot-button:hover {
    background: rgb(48, 46, 46);
    opacity: 0.8;
}

.slick-prev,
.slick-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.slick-prev:before,
.slick-next:before {
    font-size: 18px;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.slick-prev {
    left: 10px;
    z-index: 1;
}

.slick-next {
    right: 10px;
    z-index: 1;
}

.slick-prev:before {
    content: '❮';
    font-size: 24px;
}

.slick-next:before {
    content: '❯';
    font-size: 24px;
}