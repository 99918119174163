.newsletter-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.newsletter {
  overflow: hidden;
  height: 40px;
  width: 300px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
}

.newsletter .country-code-container {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
}

.newsletter img.flag-icon {
  width: 20px;
  height: 16px;
  margin-right: 5px;
  border-radius: 2px;
}

.newsletter span.country-code {
  font-weight: bold;
  color: #555;
  font-size: 16px;
}

.newsletter input {
  width: 100%;
  border: 2px solid #61BF62;
  border-radius: 21px;
  height: 40px;
  padding-left: 64px;
  text-transform: uppercase;
  outline: none;
  transition: border 0.2s ease;
}

.newsletter input::placeholder {
  text-transform: none;
}

.newsletter input:focus {
  border: 2px solid #61BF62;
}

.newsletter input:focus+button {
  background-color: #61BF62;
}

.newsletter button {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  border: none;
  background-color: #61BF62;
  transition: background-color 0.3s ease, width 0.3s ease;
  color: #fff;
  font-weight: bold;
  padding: 0;
  margin: 0;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  text-transform: capitalize;
  width: 120px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 1024px) {
  /* .newsletter-container {
    padding: 0px;
  } */
}

.newsletter button:hover {
  width: 125px;
  background-color: #61BF62;
}

.dark-mode input {
  background-color: #333;
  color: #fff;
}

.light-mode input {
  background-color: #fff;
  color: #000;
}

.error-message {
  position: absolute;
  margin-top: 40px;
  margin-left: -16px;
  color: red;
  font-size: 12px;
}