.buttonCompare {
    margin-right: 8px !important;
    border-radius: 20px !important;
    min-width: 120px !important;
    transition: background-color 0.3s, transform 0.2s;
    text-transform: capitalize !important;
}

.buttonCompare.silver {
    background-color: #01B247;
    color: #fff;
}

.buttonCompare.silver:hover {
    background-color: #01B247;
    color: #fff;
}

.buttonCompare.gold {
    background-color: #01B247;
    color: #fff;
}

.buttonCompare.gold:hover {
    background-color: #01B247;
    color: #fff;
}

.buttonCompare.platinum {
    border: 1px solid #01B247;
    background-color: #C8E9DF;
    color: #01B247;
}

.buttonCompare.platinum:hover {
    background-color: #C8E9DF;
    color: #01B247;
}

.buttonCompare:active {
    transform: scale(0.95);
}

.buttonCompare:last-child {
    margin-right: 0;
}

@media (max-width: 768px) {
    .buttonCompare {
        min-width: 100px !important;
        padding: 6px 12px;
        font-size: 0.9rem;
    }

    .buttonCompare {
        margin-right: 8px !important;
    }
}