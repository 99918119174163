/* Base styles for desktop and larger screens */
.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 45px;
    position: relative;
}

.banner-container.dark-mode .shape-left,
.banner-container.dark-mode .shape-top {
    background-color: #333333;
}

.banner-image {
    text-align: center;
    display: block;
    width: 81%;
    max-width: 3445px;
    height: auto;
    object-fit: cover;
}

/* Play Button Container */
.playButtonContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5), 0 0 25px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    animation: breathePlay 1.5s infinite;
    cursor: pointer;
}

.playButtonContainer .playButton {
    color: red;
    background-color: #fff;
    border-radius: 50%;
    font-size: 90px;
}

/* Shape Styles */
.shape {
    position: absolute;
    border-radius: 50%;
    opacity: 0.7;
    z-index: 0;
}

.shape-left {
    width: 350px;
    height: 350px;
    background-color: #0c4fe01f;
    left: -75px;
    top: 25%;
    animation: moveLeft 5s infinite alternate;
}

.shape-top {
    width: 350px;
    height: 350px;
    background-color: #25d3652a;
    right: -75px;
    top: 0%;
    animation: moveRight 5s infinite alternate;
}

@keyframes breathePlay {
    0% {
        box-shadow: 0 0 0 0 rgb(211, 37, 37);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes moveLeft {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(50px, 0);
    }
}

@keyframes moveRight {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-50px, 0);
    }
}

@media (max-width: 768px) {
    .playButtonContainer {
        width: 70px;
        height: 70px;
    }

    .playButtonContainer .playButton {
        font-size: 45px;
    }

    .shape-left {
        width: 130px;
        height: 130px;
    }

    .shape-top {
        width: 130px;
        height: 130px;
    }
}

/* For small devices (phones) */
@media (max-width: 600px) {
    .playButtonContainer {
        width: 45px;
        height: 45px;
    }

    .playButton {
        font-size: 35px;
    }
}

/* Centered Image and Play Button */
.imageWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}