.whatsapp-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25D366;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    animation: breathe 2s ease-in-out infinite;
    cursor: pointer;
}

.whatsapp-btn i {
    color: #fff;
    font-size: 24px;
    animation: beat 2s ease-in-out infinite;
    text-decoration: none;
}

@keyframes breathe {
    0% {
        box-shadow: 0 0 0 0 rgba(15, 67, 7, 0.5);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(14, 100, 45, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes beat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.chat-box-container {
    background-color: #075E54;
}

.whtsapp-main-container {
    background-color: #FFF;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
}

.logo-container img {
    width: 43px;
    border-radius: 50%;
    height: 43px;
    margin: 10px;
}

.chat-container {
    border: 1px solid black;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 90%;
    max-width: 600px;
    font-size: 14px;
    border-radius: 5px;
    margin: 10px auto;
    flex-grow: 1;
    overflow-y: auto;
}

.chat-middle-container {
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 90%;
    max-width: 600px;
    font-size: 14px;
    border-radius: 5px;
    margin: 10px auto;
    height: auto;
    flex-grow: 1;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(205, 187, 187);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(113, 107, 107, 0.673);
    border-radius: 5px;
}

.outline-button-whtsapp {
    width: 100%;
    margin-top: 16px;
    background-color: #8daef531;
    border: 1px solid #0C4FE0;
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;    
}

.outline-button-whtsapp:hover {
    border-color: #0C4FE0;
    background-color: #0C4FE0;
    color: #fff;
}

.outline-button-whtsapp a {
    color: inherit;
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
}

.outline-button-whtsapp a:hover {
    border-color: #0C4FE0;
    background-color: #0C4FE0;
    color: #fff;
}

.chat-bottom-timing {
    border: 1px solid black;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 90%;
    max-width: 600px;
    font-size: 14px;
    border-radius: 5px;
    margin: 10px auto;
    height: auto;
}

.playground {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-send-msg {
    width: 100%;
    max-width: 400px;
    height: 36px;
    background: white;
    border: 1px solid gray;
    border-radius: 20px;
    margin: 10px 10px;
}

.container-send-msg .content {
    padding: 0 12px;
    height: 100%;
    display: flex;
    align-items: center;
}

.container-send-msg .content input {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    height: 100%;
    background: transparent;
}

.container-send-msg .content input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.container-send-msg .content i {
    flex: 0 0 auto;
    margin-left: 5px;
}

.todayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.todayContainer .today {
    text-align: center;
    background-color: #d6eaf5;
    width: 60px;
    padding: 2px;
    color: #667781;
    font-size: 14px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

.chat-send-time {
    font-size: 10px;
    color: #667781;
    float: right;
    margin-top: -20px;
}

.chat-count {
    background-color: red;
    height: 22px;
    width: 22px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    margin-top: -43px;
    margin-left: 43px;
    color: #fff;
    font-size: 13px;
    font-weight: bolder;
}

@media (max-width: 600px) {
    .container-send-msg {
        width: 100%;
        max-width: none;
        margin-left: 13px;
    }

    .chat-container,
    .chat-middle-container,
    .chat-bottom-timing {
        width: 95%;
    }

    .logo-container img {
        width: 50px;
        height: 50px;
    }

    .chat-count {
        margin-left: 35px;
    }

    .close-chat-btn {
        position: absolute;
        right: 0;
    }
}

@media (max-width: 476px) {

    .chat-container,
    .chat-middle-container,
    .chat-bottom-timing {
        width: 93%;
    }

    .logo-container {
        width: 70%;
    }

    .close-chat-btn {
        position: absolute;
        right: 0;
    }
}