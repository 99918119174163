@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.badge-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    justify-items: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 600px) {
    .badge-grid {
        display: flex;
    }

    .badge-grid.dark {
        background-color: #333333;
    }

    .badge-item {
        flex: 0 0 40%;
        scroll-snap-align: center;
    }

    .badge-grid::-webkit-scrollbar {
        display: none;
    }

    .badge-grid {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.carousel-item {
    display: grid;
    gap: 1px;
    justify-items: center;
    align-items: center;
    text-align: center;
    ;
}

.badge-icon {
    margin-bottom: 8px;
}


@media (max-width: 1024px) {
    .badge-grid {
        display: flex;
        padding: 5px;
    }

    .badge-item {
        flex: 0 0 50%;
        scroll-snap-align: center;
    }

    .badge-grid::-webkit-scrollbar {
        display: none;
    }

    .badge-grid {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.badge-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.badge-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 0px;
}

.badge-grid.dark .badge-gray {
    background-color: #333333;
}

.badge-grid.dark .badge-blue {
    background-color: #333333;
}

.badge-grid.dark .badge-purple {
    background-color: #333333;
}

.badge-grid.dark .badge-text {
    color: #fff;
}

.badge-gray {
    background-color: #fff;
}

.badge-blue {
    background-color: #fff;
}

.badge-purple {
    background-color: #fff;
}

.badge-text {
    font-size: 14px;
    color: #333;
    text-align: center;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.badge-summary {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    margin-top: -9px;
}