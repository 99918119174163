.faq {
    padding: 0px 0 28px;
}

.section-title h2 {
    margin-bottom: 22px;
}

.accordion-header {
    color: rgba(30, 48, 86, 0.8);
    font-size: 18px;
    font-weight: bold;
    padding: 0 30px 0 18px;    
}

.faq.dark .accordion-header{
    color: #fff;
}

.faq.dark .accordion-answer{
    color: #fff;
}

.MuiAccordionSummary-expandIconWrapper {
    background: #3F74E6;
    border-radius: 50%;
    color: #fff;
}

.MuiAccordion-root {
    border-radius: 10px;
    box-shadow: 0px 15px 140px 0px rgba(69, 81, 100, 0.1);
    margin-bottom: 30px;
}

.MuiAccordionSummary-root {
    background: #fff;
    border-radius: 15px !important;
    box-shadow: 0px -50px 140px 0px rgba(69, 81, 100, 0.1);
    height: 80px;
    position: relative;
}

.faq.dark .MuiAccordionSummary-root {
    background: #333333;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    background: #3F74E6;
}

.MuiAccordionDetails-root {
    padding: 24px 72px 23px;
    color: rgba(30, 48, 86, 0.8);
    line-height: 30px;
}